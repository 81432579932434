import React from "react";
import bannerOne from "../assets/about_us_banner_1.png";
import bannerTwo from "../assets/about_us_banner_2.png";

const AboutUs = () => {
    return (
        <section className="w-100 max-sm:h-auto h-[621px] relative" id="home_about_us">
            <img src={bannerOne} className="left-[0px] bottom-[0px] absolute max-sm:hidden" />
            <img src={bannerTwo} className="right-[0px] bottom-[0px] absolute max-sm:hidden" />
            <div
                className="h-[621px] max-sm:h-auto w-[50%] max-sm:w-[100%] bg-white absolute max-sm:static z-10 max-sm:z-0 left-[0px] right-[0px] py-[50px] px-[40px] max-sm:px-[10%] max-sm:py-[10%]"
                style={{ marginInline: "auto" }}
            >
                <h2 className="text-mam-orange font-primaryBold text-[40px] max-sm:text-[30px] mb-[15px]">SOBRE NOSOTROS</h2>
                <p className="font-primaryLight text-[18px] max-sm:text-[16px]">Bienvenido a MAM Servicios, su aliado confiable en soluciones integrales para el mantenimiento y mejoramiento de tus instalaciones. Con una sólida experiencia en el mercado, nos enorgullece ofrecer una amplia gama de servicios de alta calidad que abarcan desde refrigeración hasta construcción y pintura.</p>
                <br />
                <p className="font-primaryLight text-[18px] max-sm:text-[16px]">En MAM Servicios, nos comprometemos a brindar resultados excepcionales a cada cliente, satisfaciendo sus necesidades con profesionalismo y eficiencia.</p>
                <br />
                <br />
                <a 
                    href="#home_services" 
                    className="h-[59px] w-[354px] bg-transparent mx-auto mt-[51px] border text-mam-orange border-mam-orange font-primaryLight hover:font-primaryMedium text-[18px] hover:bg-mam-orange hover:text-white px-[25px] max-sm:px-[10px] py-[18px] text-center mt-[25px] max-sm:text-[14px]">
                    CONOCÉ NUESTROS SERVICIOS
                </a>
            </div>
        </section>
    )
}

export default AboutUs;