import React from "react";
import ServiceBackground from "../assets/services_bg.jpg";
import ServiceCard from "./templateParts/ServiceCard";
import RefrigeracionImage from "../assets/refrigeracion.png"
import HerreriaImage from "../assets/herreria.png"
import PinturaImage from "../assets/pintura.png"
import PlomeriaImage from "../assets/plomeria.png"
import ConstruccionImage from "../assets/construccion.png"
import ElectricidadImage from "../assets/electricidad.png"

const Services = () => {
    return (
        <section 
            className="w-100 h-[1345px] max-sm:h-auto pt-[85px] max-sm:pb-[40px] max-sm:pt-[40px]" 
            style={{ 
                backgroundImage: `url(${ServiceBackground})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"
            }}
            id="home_services"
        >
            <h2 className="mb-[70px] max-sm:mb-[35px] text-center text-[40px] max-sm:[30px] text-white font-primaryBold">SERVICIOS</h2>
            <div className="w-[80%] max-sm:w-100 mx-auto grid grid-cols-2 max-sm:grid-cols-1 gap-x-[22px] gap-y-[22px]">
                <ServiceCard 
                    title="REFRIGRERACIÓN" 
                    text="Nuestro equipo de expertos en refrigeración está dedicado a garantizar el óptimo funcionamiento de tus sistemas de refrigeración, ya sea para grandes empresas o pequeños negocios. Desde la instalación y mantenimiento hasta la reparación y actualización de equipos, en MAM Servicios nos ocupamos de mantener sus espacios frescos y funcionales en todo momento." 
                    image={RefrigeracionImage}
                />
                <ServiceCard 
                    title="PINTURA" 
                    text="Transforme sus espacios con los servicios de pintura de MAM Servicios. Ya sea que necesite darle un nuevo aspecto a sus instalaciones comerciales o residenciales, nuestro equipo de pintores expertos utiliza técnicas y materiales de alta calidad para ofrecerle resultados impresionantes que superen sus expectativas." 
                    image={PinturaImage}
                />
                <ServiceCard 
                    title="HERRERÍA" 
                    text="Nuestro equipo de herrería ofrece soluciones personalizadas para tus necesidades estructurales y estéticas. Desde la creación de barandales y rejas hasta la reparación y mantenimiento de estructuras metálicas, estamos comprometidos con la calidad y la durabilidad. Trabajamos con precisión y habilidad para asegurar que cada proyecto no solo cumpla con tus expectativas, sino que también resista la prueba del tiempo." 
                    image={HerreriaImage}
                />
                <ServiceCard 
                    title="PLOMERÍA" 
                    text="Los problemas de fontanería pueden surgir en cualquier momento, y en MAM Servicios estamos aquí para resolverlos. Desde reparaciones simples hasta instalaciones complejas, nuestro equipo de plomeros matriculados altamente capacitados ofrece servicios de plomería confiables y eficientes para garantizar el funcionamiento adecuado de sus sistemas de agua." 
                    image={PlomeriaImage}
                />
                <ServiceCard 
                    title="CONSTRUCCIÓN" 
                    text="Desde pequeñas remodelaciones hasta proyectos de construcción a gran escala, en MAM Servicios contamos con la experiencia y los recursos necesarios para llevar a cabo cualquier proyecto de construcción con éxito. Nuestro compromiso con la calidad y la atención al detalle garantiza resultados duraderos y satisfactorios para cada cliente." 
                    image={ConstruccionImage}
                />
                <ServiceCard 
                    title="ELECTRICIDAD" 
                    text="Nuestro equipo de electricistas calificados se especializa en mantener tu sistema eléctrico en perfecto estado. Ofrecemos instalación, mantenimiento y reparación de todo tipo de sistemas eléctricos, garantizando un suministro de energía seguro y eficiente. Ya sea que necesites actualizar tu infraestructura eléctrica o resolver
problemas urgentes, estamos aquí para asegurarnos de que tu espacio funcione con total confianza y fiabilidad." 
                    image={ElectricidadImage}
                />
                
            </div>
        </section>
    )
}

export default Services;