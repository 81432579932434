import React from "react";
import mainLogo from '../assets/fondo.jpg';
import Navbar from "./templateParts/Navbar";
import logo from '../assets/logo.png';
import LogoMobile from "../assets/mam-logo-mobile.png"

const Hero = () => {
    return (
        <div
            className="w-100 h-[100vh] max-sm:h-[100vh] pt-[31px] max-sm:pt-0"
            style={{
                backgroundImage: `url(${mainLogo})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"
            }}
        >
            <Navbar />
            <img className="mx-auto mt-[115px] max-sm:hidden" src={logo} />
            <img className="mx-auto mt-[115px] md:hidden" src={LogoMobile} />
            <div className="flex justify-center">
                <a 
                    href="#home_about_us" 
                    className="h-[59px] w-[354px] max-sm:w-[80%] bg-transparent mx-auto mt-[51px] border text-mam-orange border-mam-orange font-primaryLight hover:font-primaryMedium text-[18px] max-sm:text-[15px] hover:bg-mam-orange hover:text-white px-[25px] py-[18px] max-sm:px-[15px] max-sm:text-[15px] text-center">
                    CONOCÉ MÁS DE NOSOTROS
                </a>
            </div>
        </div>
    )
}

export default Hero;