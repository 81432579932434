import React from "react";
import WhatsAppIcon from "../../assets/whatsapp-icon-white.png";

const WhatsAppBtn = () => {
    return (
        <a
            href="https://wa.me/5493624365134?text=Hola!.%20Me%20gustaría%20realizar%20una%20consulta."
            className="w-[70px] h-[70px] fixed bottom-[40px] right-[20px] max-sm:right-[10px] bg-green-500 rounded-full z-50 flex justify-center items-center hover:bg-green-600"
            target="_blank">
            <img src={WhatsAppIcon} className="w-[35px] h-[35px]"/>
        </a>
    )
}

export default WhatsAppBtn;