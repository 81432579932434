import React from "react";
import MamLogo from "../../assets/mam-logo.png";
import FacebookIcon from "../../assets/facebook-icon-large.png";
import InstagramIcon from "../../assets/instagram-icon-large.png";

const Footer = () => {
    return (
        <div className="w-100 h-[177px] bg-black py-[70px] max-sm:py-[35px] max-sm:h-auto">
            <div className="w-[80%] flex justify-between max-sm:flex-col items-center mx-auto max-sm:gap-y-[25px]">
                <a href="/">
                    <img src={MamLogo} alt="MAM Servicios Logo" />
                </a>
                <p className="text-white font-primaryMedium">MAM Servicios - 2024</p>
                <div className="flex">
                    <a href="https://www.facebook.com/mamserv.ar" className="mr-[21px]" target="_blank">
                        <img src={FacebookIcon} alt="Facebook icon" />
                    </a>
                    <a href="https://www.instagram.com/mamservicios.ar" className="mr-[21px]" target="_blank">
                        <img src={InstagramIcon} alt="Instagram icon" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer;