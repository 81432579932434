import React from "react";

const ServiceCard = ({title, text, image}) => {
    return(
        <div className="w-[533px] max-sm:w-[100%] h-[320px] max-sm:h-auto relative p-[38px] bg-white overflow-hidden">
            <h3 className="text-[30px] max-sm:text-[20px] font-primaryBold text-mam-orange mb-[10px]">{title}</h3>
            <p className="font-primaryMedium text-[15.7px]">{text}</p>
            <img src={image} className="absolute top-0 right-[-55px]"/>
        </div>
    )
}

export default ServiceCard;