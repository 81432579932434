import React from "react";
import { useState } from "react";
import MobileMenu from "../../assets/mobile-menu.png";

const Navbar = () => {
    const [openMenu, setOpenMenu] = useState(false);
    return (
        <div className="w-100 max-sm:flex max-sm:justify-end max-sm:relative">
            <ul className="flex justify-center gap-[37px] list-none text-white font-primaryMedium max-sm:hidden">
                <li><a href="/" className="hover:underline">INICIO</a></li>
                <li><a href="#home_about_us" className="hover:underline">NOSOTROS</a></li>
                <li><a href="#home_services" className="hover:underline">SERVICIOS</a></li>
                <li><a href="#home_section" className="hover:underline">CONTACTO</a></li>
            </ul>
            {
                !openMenu && <img
                    src={MobileMenu}
                    alt="Facebbok icon"
                    className="md:hidden w-[35px] mr-[20px] mt-[30px]"
                    onClick={() => setOpenMenu(true)}
                />
            }
            {
                openMenu && <div className="bg-black w-[100%] text-white absolute z-10 p-[30px] font-primaryMedium text-end">
                    <span onClick={() => setOpenMenu(false)} className="p-[20px]">X</span>
                    <p className="p-[10px]" onClick={() => setOpenMenu(false)}>
                        <a href="/">INICIO</a>
                    </p>
                    <hr />
                    <p className="p-[10px]" onClick={() => setOpenMenu(false)}>
                        <a href="#home_about_us" className="hover:underline">NOSOTROS</a>
                    </p>
                    <hr />
                    <p className="p-[10px]" onClick={() => setOpenMenu(false)}>
                        <a href="#home_services" className="hover:underline">SERVICIOS</a>
                    </p>
                    <hr />
                    <p className="p-[10px]" onClick={() => setOpenMenu(false)}>
                        <a href="#home_section" className="hover:underline">CONTACTO</a>
                    </p>
                </div>
            }
        </div>
    )
}

export default Navbar;