import Hero from "./components/Hero";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Footer from "./components/templateParts/Footer";
import WhatsAppBtn from "./components/templateParts/WhatsAppBtn";

function App() {
  return (
    <div className="App">
      <Hero />
      <AboutUs />
      <Services />
      <Contact />
      <Footer />
      <WhatsAppBtn />
    </div>
  );
}

export default App;
