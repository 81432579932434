import React, { useState } from "react";
import InstagramIcon from "../assets/instagram-icon.png";
import FacebookIcon from "../assets/facebook-icon.png";
import WhatsAppIcon from "../assets/whatsapp-icon.png";
import MamBan from "../assets/mam-ban.png";
import ContactAsset from "../assets/contact_asset.png";
import ArrobaIcon from "../assets/arroba-icon.png";
import emailjs from '@emailjs/browser';
import { useRef } from "react";


const Contact = () => {
    const form = useRef();
    const [sending, setSending] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSending(true);      

        emailjs
            .sendForm('service_peeb5xp', 'template_zgvaaxs', form.current, {
                publicKey: 'l86HJ0l9hiKY7YE92',
            })
            .then(
                () => {
                    setSending(false);
                    form.current.reset();
                    alert('¡Gracias por comunicarte con nosotros!');
                    console.log('SUCCESS!');
                },
                (error) => {
                    alert('Oops! Ocurrió un error al enviar el mensaje. Por favor intente más tarde.');
                    setSending(false);
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <section className="w-100 h-[622px] max-sm:h-auto pt-[51px] bg-mam-orange relative" id="home_section">
            <h2 className="mb-[50px] max-sm:mb-[30px] text-center text-[40px] max-sm:text-[30px] text-white font-primaryBold">
                COMUNICATE CON NOSOTROS
            </h2>
            <div className="flex justify-end">
                <img src={MamBan} alt="MAM Servicios Ban" style={{ width: "583px", height: "306px" }} className="max-sm:hidden" />
                <div className="w-[50%] max-sm:w-[90%] mx-auto">
                    <div className="grid grid-cols-2 gap-y-[17px] gap-x-[35px] w-[50%] max-sm:w-100 max-sm:flex max-sm:flex-col">
                        <div className="flex">
                            <img src={FacebookIcon} alt="Facebbok icon" width={21} height={21} />
                            <span className="text-white font-primaryMedium ml-[15px]">
                                <a href="https://www.facebook.com/mamserv.ar" className="no-underline hover:underline" target="_blank">mamserv.ar</a>
                            </span>
                        </div>
                        <div className="flex">
                            <img src={WhatsAppIcon} alt="instagram icon" width={21} height={21} />
                            <span className="text-white font-primaryMedium ml-[15px]">3624365134</span>
                        </div>
                        <div className="flex">
                            <img src={InstagramIcon} alt="instagram icon" />
                            <span className="text-white font-primaryMedium ml-[15px]">
                                <a href="https://www.instagram.com/mamservicios.ar" className="no-underline hover:underline" target="_blank">mamservicios.ar</a>
                            </span>
                        </div>
                        <div className="flex">
                            <img src={ArrobaIcon} alt="instagram icon" />
                            <span className="text-white font-primaryMedium ml-[15px]">contacto@mamservicios.ar</span>
                        </div>
                    </div>
                    <div className="mt-[40px]">
                        <h3 className="text-left text-[20px] text-white font-primaryBold mb-[12px]">Dejanos un mensaje</h3>
                        <form ref={form} onSubmit={handleSubmit}>
                            <div className="flex space-between max-sm:flex-col">
                                <input type="text" name="from_name" className="w-[191px] max-sm:w-[100%] h-[34px] mr-[12px] px-[11px] py-[7px] max-sm:mb-[12px]" placeholder="Nombre" required/>
                                <input type="email" name="from_email" className="w-[191px] max-sm:w-[100%] h-[34px] px-[11px] py-[7px]" placeholder="Email" required/>
                            </div>
                            <div className="mt-[12px]">
                                <textarea name="message" className="w-[394px] max-sm:w-[100%] px-[11px] py-[7px]" rows="5" placeholder="Mensaje" required/>
                            </div>
                            <div className="mt-[12px] max-sm:mb-[20px]">
                                <input
                                    type="submit"
                                    className="w-[394px] max-sm:w-[100%] h-[34px] px-[11px] py-[7px] bg-mam-yellow font-primaryMedium text-mam-blue hover:cursor-pointer"
                                    value={`${sending ? 'Enviando...' : 'Enviar'}`}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <img src={ContactAsset} alt="Contact Asset" className="absolute right-[0px] bottom-[-30px] max-sm:hidden" />
        </section>
    )
}

export default Contact;